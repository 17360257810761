@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');


.crm_report_heading{
    font-weight: 900;
    font-size: 15px;
    font-family: 'Alfa Slab One',;
}

.count_rg{
    font-family: 'Alfa Slab One',;
    margin-bottom: 0px;
    margin-left: 10px;
    font-weight: 900;
}