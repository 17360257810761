.data_table:hover {
  background: white;
  /* border: 1px solid black; */
  box-shadow: 0px 2px 0px 3px rgb(206, 206, 206);
}

.mateial_table_header {
  display: flex;
  border-bottom: 1px solid #eaeded;
  border-top: 1px solid #eaeded;
  padding: 0px 10px;
  z-index: 100;
  align-items: center;
  background: #fafafa;
}

.mateial_table_header p {
  font-size: 12px;
  text-transform: Capitalize;
  margin-bottom: 0px;
  padding: 6px 0px;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
}

.mateial_table_body {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-bottom: 1px solid #eaeded;
  cursor: pointer;
  z-index: 100;
  background-color: white;
}

.mateial_table_body p{
  margin: 0px 5px 0px 0px;
  color: #000;
}

.mateial_table_body_active p{
  margin: 0px 5px 0px 0px;
  color: #000;
}


.mateial_table_body_l {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-bottom: 1px solid #eaeded;
  cursor: pointer;
  background-color: rgb(255, 143, 143);
}

/* #0073ba */
/* radio active  */
.mateial_table_body_active {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 100;
  background-color: #f1fafe;
}

.mateial_table_body:hover {
}

.mateial_table_body p {
  font-size: 12px !important ;
  margin-bottom: 0px;
  padding: 4px 0px;
  align-items: center;
}

.mateial_table_body_active p {
  font-size: 14px;
  margin-bottom: 0px;
  padding: 5px 0px;
  /* align-items: center; */
}


.itemp{
  font-size: 12px !important;
  font-weight: 500;
}

.checked_here{
  width:16px;
  height: 16px;
  border-radius: 4px;
  background-color: white;
  border: 1.3px solid #cecece !important;
  margin-bottom: 0px !important;
  color: transparent;
  position: relative;
}

.checked_here2{
  width:17px;
  height: 17px;
  border-radius: 4px;
  background-color: white;
  border: 1.3px solid #cecece !important;
  margin-bottom: 0px !important;
  color: transparent;
  position: relative;
}


.checked_here1{
  padding: 3px;
  width: 11px;
  max-width: 16px;
  height: 11px;
  position: absolute;
  top: 1.7px;
  left: 1.5px;
  border-radius: 4px;
  margin-bottom: 0px !important;
}

.border_right_for_table{
  border-right: 1px solid #cecece;
}