.menu_option_kp_non_active {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    margin: 8px auto;
    cursor: pointer;
    margin:10px;
    opacity:1
  }
  
  .menu_option_kp_active {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    margin: 8px auto;
    cursor: pointer;
    box-sizing: border-box;
    margin:10px;
    opacity:1
  }
  
  .icon_kp {
    margin-right: 10px;
    font-size: 15px;
    opacity: 1;
  }
  
  .menu_option_kp_non_active h6 {
    color: white;
    opacity: 1;
    font-size: 14px;
    margin-bottom: 0px;
  }
  
  .icon_kp_active {
    margin-right: 10px;
    font-size: 15px;
    opacity: 1;
  }
  .menu_option_kp_active h6 {
    color: white;
    opacity: 1;
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  