table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 5px;
  font-size: 12px;
  border-bottom: 1px solid #e9e9e9;
  color: black;
}

.activetable {
  cursor: pointer;
  background-color: #f1fafe;
  border: 1px solid #00a2c9;
  border-left-color: #fff;
  border-right-color: #fff;
}

th {
  background-color: #fafafa;
  color: white;
  font-size: 12px;
}

td {
  color: black !important;
}

.active_link {
  color: #0073ba !important;
  font-weight: 900;
}

.radio_section {
  width: 50px !important;
  max-width: 50px !important;
  overflow: hidden;
}
.radio_section1 {
  background-color: #fafafa;
  width: 50px !important;
  max-width: 50px !important;
  max-height: 40px;
  overflow: hidden;
  /* padding: 8px; */
}

.scrollbarhider {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
