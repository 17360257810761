.input::placeholder {
  color: #e6e7fa;
  opacity: 1; /* Firefox */
}

/* body::-webkit-scrollbar {
  width: 12px; 
} */

body ::-webkit-scrollbar {
  width: 2px !important; /* width of the entire scrollbar */
  scrollbar-width: 10px !important;
  height: 10px;
  padding: 10px;
}

body ::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}

body ::-webkit-scrollbar-track:hover {
  background-color: rgb(250, 250, 250);
}

body ::-webkit-scrollbar-thumb {
  background-color: #f0f0f0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.ant-tabs-tab.ant-tabs-tab-active {
  /* border-bottom: 3px solid #6666c4 !important; */
  border-bottom: 3px solid  #222f3e !important;
  z-index: 2;
  font-weight: 700 !important;
  color: black !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  color: black;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #222f3e;
  border-color: #fff;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #222f3e;
  border-color: #fff;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #222f3e;
  border-color: #fff;
}

.ant-tabs-tab {
  border-bottom: 1px solid #f0f0f0 !important;
  height: 38px;
}

.ant-tabs-tab:hover {
  border-bottom: 3px solid #d6d6d6 !important;
  color: black !important;
  /* height: 40px !important; */
}
.ant-btn .ant-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1ami7lv-MuiTimelineConnector-root {
  /* color: red; */
  width: 1.2px !important;
  background-color: #e2e2e2 !important;
  flex-grow: 1;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:black !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  font-size: 12px !important;
}

.ant-tabs-tab{
  height: 30px !important;
}


.container1 {
  border: 1px solid #f3f3f3;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  overflow: auto; /* Allow scrolling if content exceeds container size */
}