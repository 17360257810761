.show_for_tab {
  display: none;
}

.navbar-search {
  background: transparent !important;
  color: rgba(209, 209, 240, 0.8) !important;
  border: none !important;
  outline-width: 0px !important;
  outline-offset: 0px;
  outline-color: transparent;
  padding: 2px 4px;
  padding-left: 10px;
}

.navbar-search::placeholder {
  color: rgba(209, 209, 240, 0.8);
  font-size: 13px;
}

.search-nav-container {
  display: flex;
  align-items: center;
  background-color: rgb(75 75 125);
  padding: 0px 10px;
  border-radius: 4px;
  flex-grow: 3 !important;
}

.menu-nav {
  font-size: 12px;
  margin: 0px 10px;
  padding-bottom: 0px;
  cursor: pointer;
  /* color: #8b8f8c; */
}

.menu-nav-active {
  font-size: 12px;
  padding: 0px 10px;
  padding-bottom: 0px;
  cursor: pointer;
}

.link-nav {
  text-decoration: none;
  color: #ffffff !important;
  font-weight: 600;
  border-left: 1px solid #414750;
  padding-left: 8px;
}

.link-nav-active {
  text-decoration: none;
  color: #8b8f8c !important;
  font-weight: 600;
  border-left: 1px solid #414750;
  /* border-bottom: 2px solid #fff; */
  padding-left: 8px;
}

.menu-item ul li {
  opacity: 1 !important;
}

.menu-item ul li:hover {
  opacity: 1 !important;
}

.nav-section {
  display: flex;
  align-items: center;
  padding: 0px 5px;
  height: 20px;
}

@media screen and (max-width: 992px) {
  .menu-item {
    display: none;
  }
  .show_for_tab {
    display: block;
  }
}

@media screen and (max-width: 602px) {
  .logo-head {
    margin-left: 10px !important;
  }

  .logo-head1 {
    padding-bottom: 0px;
    padding-top: -10px;
  }
}
