
.task_web_section{
    min-height:85vh;
    border-radius: '4px';
    width: '99%';
    min-width: 215px;
    border-left: 1px solid #e0e0e0;
    padding: 10px;
}

.task_web_sub_head {
    font-size: 11.5px;
    font-weight: 700;
    color: #52a0d0;
    margin-bottom: 3px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;

}