

.menu_option_it_non_active {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    margin-left:12px;
    margin-top: 17px;
    opacity:0.6;
}

.menu_option_it_active {
    border-right-width: 3px;
    border-radius: 2px;
    border-color: #fff;
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    margin-left:12px;
    margin-top: 17px;
    opacity:1;
}

.menu_option_it_non_active:hover{
    opacity: 1;
}