.edit_profile_picture_btn {
  border: 1.2px solid #bfbfbf;
  width: 100px;
  padding: 3px;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  /* position: absolute; */
  top: 2px;
  cursor: pointer;
}

.file_select {
  z-index: 100 !important;
  display: block;
  position: absolute;
  opacity: 0;
}

.edit_profile_picture_btn:hover {
  box-shadow: inset 0 0 0 1px #a7a7a7;
  background-color: rgb(243, 243, 243);
}

.heading_profile {
  font-weight: 600;
  font-size: 14px;
}
