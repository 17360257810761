@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;800&family=Signika+Negative:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&family=Nunito:wght@300;400;500;800&family=Signika+Negative:wght@600&display=swap');

.primarybtn {
  background: #0e68bf !important;
  border: none;
  color: white;
  padding: 2px 10px;
  /* border: 2px solid #0e68bf; */
  font-weight: 400;
  border-radius: 4px;
  size: 13px;
  margin: 10px 0px;
  z-index: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
}

/* .primarybtn:hover {
  border: 1px solid #0f5aa5;
  box-shadow: outset 0 0 20px 0 #0f5aa5;
  box-shadow: inset 0 0 0 1px #1068bf;
} */

/* .primarybtn:focus {
  border: 1px solid #0f5aa5;
  box-shadow: -1px -1px -1px 0px red;
} */

.secondarybtn {
  background: white !important;
  border: none;
  color: black !important;
  padding: 2px 10px;
  border: 1px solid #bfbfbf !important;
  font-weight: 400;
  border-radius: 4px;
  size: 13px;
  margin: 10px 5px;
  /* position: absolute; */
}

.secondarybtn:hover {
  border: 1px solid rgb(139, 139, 139);
  background-color: #fcfcfc !important;
  box-shadow: outset 0 0 20px 0 #0f5aa5;
  /* box-shadow: inset 0 0 0 1px #1068bf; */
}
