

.messagebox_outer{
    width:350px;
    height:400px;
    max-height:400px;
    background-color: #fff;
    position: fixed;
    z-index: 100;
    bottom: -1px;
    right: -1.5px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow:0 2.8px 2.2px rgba(0, 0, 0, 0.034);
    border:1px solid #ebebeb
}

.messagebox_outer1{
    width:350px;
    height:34px;
    background-color: #fff;
    position: fixed;
    z-index: 100;
    bottom: -1px;
    right: -1.5px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow:0 2.8px 2.2px rgba(0, 0, 0, 0.034);
    border:1px solid #ebebeb
}

.chatbox_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(75 75 125);
    padding: 8px 8px;
    height: 35px;
}

.chatbox_header h6{
    font-size: 12px;
    font-weight: 700;
    color: white;
    margin-bottom: 0px;
}


.message_footer_chat{
    padding: 6px 5px;
    max-width:350px;
    min-width:350px;
    min-height: 80px;
    max-height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 10px !important;
    border-top: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 10px;
}

.input_style{
    width: 100%;
    min-width: 100%;
    border-right: 1px solid #ebebeb;
    border: none;
    padding: 10px;
    min-height: 40px;
    max-height: 40px;
    outline: none;
    overflow-wrap: break-word;
    background-color: #f1f1f1;
}

.icon_in_chat{
    cursor: pointer;
    color: #bebebe;
}

.message_section{
    background:#fff7e8;
    width: 100%;
    min-height:71.5% ;
    max-height: 71.5%;
    padding: 10px;
    overflow-y: scroll;
    background-image: url("../../images/whatsapp_background.jpeg");
    background-size: contain;
    background-position: center;
    backface-visibility: hidden;
}

.arrow_up_message{
    width: 6px;
    height: 18p;
    background-color: rgb(255, 255, 255);
    position: absolute;
    left: 1.3px;
    top:0.4px;
    margin-top: 0px;
    transform: rotate(152deg);
    z-index: 10
}

.arrow_up_message1{
    width: 6px;
    height: 18px;
    background-color: rgb(205, 255, 196);
    position: absolute;
    right: 1.3px;
    top: 0.4px;
    margin-top: 0px;
    transform: rotate(-152deg);
    z-index: -10
}


.message_box_receive{
    z-index: 100;
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 4px 4px 4px 4px;
    padding: 6px;
    min-width: 170px;
    max-width: 80%;
    display: inline-block;
    margin: 2px;
}


.message_box_send{
    z-index: 100;
    position: relative;
    background-color: rgb(205, 255, 196);
    border-radius: 4px 4px 4px 4px;
    padding: 6px;
    min-width: 170px;
    max-width: 80%;
    /* display: inline-block; */
    align-self: flex-end;
    justify-self: end;
    margin: 2px;
    float: right;
}

.message_box_receive h6, .message_box_send h6{
    max-width: 100%;
    font-size: 10px;
    border-radius: 0px 4px 4px 4px;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 0px;

}

.message_box_receive h4 ,.message_box_send h4{
    font-size:9px;
    margin-bottom: 2px;
    margin-left: 0px;
    font-weight: 600;
    color: #cf3f3a;

}


.message_box_receive span ,.message_box_send span{
    font-size: 5px;
    position: absolute;
    bottom: 0px;
    right: 4px;
    color: #919191;
    font-weight: 600;
    margin-top: 2px;
}

.comment_Attachment{
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 100;
    margin-left:-3%;
    bottom:78px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

}

.comment_Attachment h6{
    font-size: 12px;
    margin-bottom: 0px;
}