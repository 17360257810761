.loader-outer {
  min-height: 100vh !important;
  max-height: 100%;
  z-index: 250;
  position: absolute;
  /* width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
  position: absolute;
  z-index: 200; */
}

.loader {
  background-color: black;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  max-height: 100%;
  position: absolute;
  opacity: 0.5;
  z-index: 200;
  display: grid;
  text-align: center;
  align-items: center;
}

.center_loading{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.com_logo{
   width: 50px;
}

.msg-loading {
  color: white;
  z-index: 200;
  font-size: 14px;
  opacity: 0.8;
  margin-top: 2px;
  font-weight: bolder;
  display: grid;
 
}
