
.section_2_analytics{
    display: flex;
    width: 102%;
    max-height: 400px !important;
    /* background-color: red; */
}

.section_2-1{
  margin: 0px 10px;
  /* max-width: 40%; */

}

.section_2-2{
    min-width: 30%;
}

.xaxies{
  color: white;
}

.recharts-cartesian-axis-tick {    
  font-size: 10px;
  color: black;
  font-weight: 700;
  font-family: Roboto, sans-serif;
}