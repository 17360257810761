@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@600&display=swap');

.sticky-hone-nav-bar {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 100;
  background-color:#292961;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  background-size: cover;
}
.sticky-hone-nav-bar-color{
  background-color:#222f3e;
}

.subheader_nav{
  background-color: #f5f5f7;
  padding-bottom: 0px;
  padding: 6px;
  text-align: center;
  font-size: 12px;
  color: rgb(128, 128, 128);
  font-weight: 800;
  font-family: 'Montserrat', sans-serif; 
}
.nav_link{
  margin-bottom: 0px;
  font-weight: 900 !important;
  font-size: 12px;
  font-family:'Source Sans Pro', sans-serif;
  color: white;
}
.nav_link a{
  font-weight: 900 !important;
  font-size: 12px;
}

.nav_link1{
  margin-bottom: 0px;
  font-weight: 900;
  font-size: 12px;
  font-family:'Source Sans Pro', sans-serif;
  color: white;
  display: none;
}
.navbar-child{
  width:70%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white !important;
  padding: 6px 0px;
}
.inactive{
  opacity: 0.8;
  cursor: pointer;
}
.inactive:hover{
  opacity: 1;
}
.header_title {
  color: black;
  font-size: 30px;
  text-align: center;
  font-weight: 900;
  font-family: 'Alfa Slab One',;
}
.header_description {
  color: #6e6e6e;
  opacity: .8;
  font-size: 15px;
  width: 60%;
  margin:auto;
  text-align: center;

  /* line-height: 1.5rem; */
}
.grid_container {
 margin-top: 30px !important;
}
.heading2 {
  text-align: center;
  width: 100%;
  margin-top: 60px;
  font-size: 18px;
  font-weight: 400;
  font-family: 'Alfa Slab One';
}
.heading3 {
  text-align: center;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 800;
  color: #585858;
  opacity: 0.8;
}
.heading4{
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}
.heading5{
  font-size: 20px;
  font-family:'Signika Negative', sans-serif;
  font-weight: 900;
}
.heading6{
  font-size: 14px;
  font-family:'Signika Negative', sans-serif;
  color: #949494;

}
.section2{
   background-color:#f5f5f7 ;
   padding: 30px 0px;

}
.section2_sub{
  width: 70%;
  margin:auto;
  margin-top: 40px;
  display: flex;

  align-items: center;
  justify-content: space-between;
}
.section3{
  width: 70%;
  margin:auto;
  background-color: #fff;
  padding: 50px 0px;
}

.grid_outer_main_team{
  padding: 0px;
  display: grid;
  grid-template-columns: auto auto auto;
  /* background-color: black; */
  gap: 10px;
  
}

.item1{
 color: white;
 /* border: 1px solid white; */
 position: relative;

}

.item1 img{
  z-index: -100;
  cursor: pointer;
}

.item1 .content1{
  z-index:100;
  position: absolute;
  bottom: -100%;
  background:#000000;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transition: bottom 0.5s ease;
}

.grid_inner_team1:hover .content1{
  bottom: 0%;
  opacity: 1;
}

.grid_inner_team2:hover .content1{
  bottom: 0%;
  opacity: 1;
}
.grid_inner_team3:hover .content1{
  bottom: 0%;
  opacity: 1;
}
.grid_inner_team4:hover .content1{
  bottom: 0%;
  opacity: 1;
}
.content1 h1{
  font-size: 13px;
  font-weight: bolder;
  color: white !important;
  margin-bottom: 3px;
  cursor: pointer;
}

.content1 h6{
  color: white;
  font-size: 12px;
  margin-bottom: 4px;
  cursor: pointer;
}

.grid_inner_team1 {
  grid-column-start: 1;
  grid-column-end: 3;
  overflow: hidden;
  height: 140px;
}

.grid_inner_team2 ,.grid_inner_team3  {
  overflow: hidden;
  height: 140px;
}  

.grid_inner_team4{
  grid-column-start: 2;
  grid-column-end: 4;
  height: 140px;
  overflow: hidden;
}

.section3_sub{
  width: 100%  !important;
  display: flex  !important;
  flex-direction: column  !important;
  justify-content: center !important;
  align-items: center  !important;
  padding: 30px 0px  !important;
}

.lets_btn{
  width: 20%;
  margin-top: 5px;
  background: black !important;
  color: white !important;
}

.team_main_heros{
  height: 180px;
}

@media screen and (max-width: 600px) {
  .sticky-hone-nav-bar{
    height: 45;
  }
  .nav_section_right {
    min-width: 140px;
    /* background-color: red; */
  }
  .img_banner{
    height: auto;
  }
  .header_title {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    width: 90%;
  }
  .header_description {
    font-size: 14px;
    font-weight: 400;
    width: 90vw !important;
    text-align: center;
    margin-top: 10px;
    margin-left: -5vw;
    line-height: 1.4rem;
  }

  .grid_container {
    display: flex;
    margin-top: 40px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .heading2 {
    text-align: center;
    margin-top: 60px;
    font-size: 15px;
    font-weight: 400;
  }
  .heading3 {
    text-align: center;
    margin-top: 0px;
    font-size: 13px;
    font-weight: 800;
    color: #07052c;
  }
  .lets_btn{
    width: 60%;
  }
  .banner_img{
    height: 50vh;
  }
}



@media screen and (max-width: 600px) {
 .nav_link{
   display: none;
 }
 .nav_link1{
   display: block;
 }

 .navbar-child{
   width: 94%;
 }
}