.active_theme{
    position: relative;
}

.active_theme:hover{
   border-radius: 50%;
   transition:ease-in-out;
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.active_theme_children:hover{
    transform:scale(0.9);
    transition:ease-in-out;
}