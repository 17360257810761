@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.footer_policy{
  width: 70%;
  display: flex;
  justify-content: space-between;
  padding: 6px 0px;
  border-top: 1px solid rgb(196, 196, 196);
  margin:auto;
}

.footer-main-color{
  background-color:#222f3e !important;
}
.footer_policy p {
  font-size: 11px;
  margin-bottom: 0px;
}

.footer_policy_section1{
  display: flex;
}

.footer_policy_section1 .l1{
 padding-left: 10px;
 border-left: 2px solid rgb(192, 192, 192);
 margin-left: 10px;
}


.footer_heading{
  font-size: 14px;
  font-weight: 900;
  color: white;
}

.footer_text{
  font-size: 12px;
  margin-bottom: 3px;
}

.link {
  color:#03c2fc;
  font-weight: bold;
}

.section_footer{
  padding: 15px 0px;
}

.ul_list{
  list-style: none;
  display: flex;
  float: left !important;
  width: 60%;
  justify-content: space-between;
  margin-left: -10%;
}

.ul_list li {
  cursor: pointer;
}