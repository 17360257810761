.active-tab-cont {
  min-width: 120px !important;
  padding: 10px 4px;
  font-size: 13px;
  margin: 0px 10px;
  text-align: center;
  background-color: #f0faff;
  border-radius: 5px;
  opacity: 0.7;
  font-weight: 600;
}

.inactive-tab-cont {
  min-width: 120px !important;
  padding: 10px 4px;
  font-size: 13px;
  margin: 0px 10px;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  opacity: 0.7;
  font-weight: 600;
}

.hidescroll_bar::-webkit-scrollbar {
  display: none;
}
